import React from "react";

const HeroSection = () => {
  return (
    <section id="hero-9" className="bg-fixed hero-section division">
      <div className="container">
        <div className="row d-flex align-items-center">
          {/* HERO TEXT */}
          <div className="col-md-8 col-lg-6">
            <div className="hero-9-txt">
              {/* Title */}
              <h2>Best Nails For Best Moments</h2>

              {/* Text */}
              <p className="p-title-md">
              <p>We invite you to our place for the most beautiful nails, perfect for your best moments.</p>
              </p>
            </div>
          </div>{" "}
          {/* END HERO TEXT */}
        </div>{" "}
        {/* End row */}
      </div>{" "}
      {/* End container */}
    </section>
  );
};

export default HeroSection;

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD6zbWbgLrhAwRlyWSgvJFX1oqlgJc4_84",
  authDomain: "nail-app-a7eee.firebaseapp.com",
  databaseURL: "https://nail-app-a7eee-default-rtdb.firebaseio.com",
  projectId: "nail-app-a7eee",
  storageBucket: "nail-app-a7eee.appspot.com",
  messagingSenderId: "1025643123824",
  appId: "1:1025643123824:web:df71e74599e9e008c290b5",
  measurementId: "G-H3WJ53TG8S"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;

import React from 'react';

const About = () => {
  return (
    <section id="about-5" className="wide-100 about-section division">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 order-last order-md-2">
            <div className="txt-table left-column wow fadeInRight">
              <table className="table">
                <tbody>
                  <tr>
                    <td>Monday – Friday</td>
                    <td>-</td>
                    <td className="text-end">10:00 AM - 7:00 PM</td>
                  </tr>
                  <tr>
                    <td>Saturday</td>
                    <td>-</td>
                    <td className="text-end">10:00 AM - 6:00 PM</td>
                  </tr>
                  <tr className="last-tr">
                    <td>Sunday</td>
                    <td>-</td>
                    <td className="text-end">11:00 AM - 6:00 PM</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6 order-first order-md-2">
            <div className="txt-block right-column wow fadeInLeft">
              <span className="section-id">Time Schedule</span>
              <h3 className="h3-xs">Working Hours</h3>
              <p className="p-lg">
              Our salon is open to serve you with flexible working hours. Visit us any day of the week to experience our exceptional services. We ensure that your time with us is both relaxing and convenient, providing you with the best care and attention.

              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
